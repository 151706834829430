<template>
  <div class="drafts">
    <div class="drafts__head">
      <a-icon
        class="drafts__close"
        type="arrow-left"
        @click="goBack"
      />
      <h1 class="drafts__title ellipsis">{{ $t('drafts.title') }}</h1>
    </div>
    <a-empty
      v-if="noDrafts"
      class="drafts__empty"
      :image="emptyImage"
    />
    <template v-else>
      <the-table
        class="drafts__table"
        :config="tableConfig"
        :defaultSort="sortParams"
        :data="draftsTableData"
        canReadModel
        @setSortParams="setSortParams"
        @entityClick="gotoCompare"
      />
      <a-alert
        v-if="errors"
        class="drafts__errors"
        :message="$t('drafts.saveError.message')"
        :description="$t('drafts.saveError.description')"
        type="error"
      />
      <div class="drafts__footer">
        <a-button
          type="primary"
          @click="saveAll"
        >
          {{ $t('drafts.saveAll') }}
        </a-button>
        <a-button
          type="danger"
          @click="dropAll"
        >
          {{ $t('drafts.resetAll') }}
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import store from '@/store';
import { storage, sortDataEntriesByField } from '@/helpers';
import EntityService from '@/services/EntityService';
import TheTable from '../page/table/TheTable.vue';

const MAX_PARALLEL_QUERIES = 3;

export default {
  name: 'DraftsPage',
  components: { TheTable },
  data() {
    return {
      errors: false,
      queue: null,
      skipped: null,
      queries: [],
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      activeKey: [''],
      sortParams: this.getStoredSortParams(),
      tableConfig: this.getTableConfig(),
    };
  },
  computed: {
    meta() {
      return store.state.meta;
    },
    drafts() {
      return Object.entries(store.state.formsDrafts)
        .map(([type, drafts]) => ({
          type,
          list: Object.entries(drafts).map(([id, draft]) => ({ id, type, ...draft })),
        }))
        .filter((group) => group.list.length);
    },
    draftsList() {
      return this.drafts.map((group) => group.list.map((draft) => draft)).flat();
    },
    draftsTableData() {
      let rows = this.draftsList.map((draft) => ({
        id: draft.id,
        type: draft.type,
        changeTime: draft.changeTime,
        title: draft.data.title || '',
      }));

      if (this.sortParams[0]) {
        const { field, direction } = this.sortParams[0];
        rows = sortDataEntriesByField(rows, field, direction === 'DESC');
      }

      return { rows };
    },
    noDrafts() {
      return this.draftsList.length === 0;
    },
  },
  methods: {
    dropAll() {
      store.mutate.deleteAllDrafts();
    },

    isDraftContainsTempRefs(draft) {
      return Object.values(draft).some((value) => {
        let hasTempRef = false;
        if (Array.isArray(value)) {
          hasTempRef = value.some(this.isDraftContainsTempRefs);
        } else if (value && typeof value === 'object') {
          hasTempRef = this.isDraftContainsTempRefs(value);
        } else if (typeof value === 'string' && value.includes('_temp')) {
          hasTempRef = true;
        }
        return hasTempRef;
      });
    },

    async saveAll() {
      this.errors = false;
      this.skipped = [];
      this.queue = this.draftsList;
      this.saveNext();
    },

    saveNext() {
      while (this.queue.length && this.queries.length < MAX_PARALLEL_QUERIES) {
        const entry = this.queue.shift();
        if (this.isDraftContainsTempRefs(entry.data)) {
          this.skipped.push(entry);
        } else {
          this.saveEntity(entry);
        }
      }

      if (!this.queue.length && !this.queries.length && this.skipped.length) {
        this.queue = this.skipped;
        this.skipped = [];
        this.saveNext();
      }
    },

    async saveEntity({ id, type, data }) {
      const key = `${type}:${id}`;
      this.queries.push(key);

      const { response, errors } = await EntityService.createOrUpdate(
        this.meta.components[type].fields,
        {
          id,
          type,
          data,
        },
      );

      if (errors) this.errors = true;

      if (response.id) {
        this.queries.splice(this.queries.indexOf(key), 1);
        store.mutate.deleteFormDraft(type, id);
        this.saveNext();
      }
    },

    getTableConfig() {
      return {
        displayType: {
          value: 'table',
        },
        displayFields: [
          {
            name: 'type',
            label: this.$t('drafts.table.type'),
            index: 'type',
            renderer: 'string',
            sortable: true,
          },
          {
            name: 'title',
            label: this.$t('drafts.table.title'),
            index: 'title',
            renderer: 'string',
            sortable: true,
          },
          {
            name: 'changeTime',
            label: this.$t('drafts.table.updatedAt'),
            index: 'changeTime',
            renderer: 'date-time',
            sortable: true,
          },
        ],
      };
    },

    getStoredSortParams() {
      return (
        storage.get(`draftsSorting`) || [
          {
            direction: 'DESC',
            field: 'changeTime',
          },
        ]
      );
    },

    setSortParams(params) {
      storage.set(`draftsSorting`, params);
      this.sortParams = params;
    },

    gotoCompare(id, type) {
      this.$router.push({
        name: 'DraftCompare',
        params: { type, id },
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.drafts {
  @include scrollbars();
  width: 100%;
  height: 100%;
  padding: 10px 20px 30px;
  overflow-y: scroll;
  position: relative; // что-то иначе наползает на заголовок страницы при схлопнутом сайдбаре

  h3 {
    margin: 0;
  }

  &__title {
    display: inline-block;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__empty {
    max-width: 200px;
  }

  &__table {
    margin: 30px 0;
  }

  &__footer {
    margin-top: 20px;

    button + button {
      margin-left: 20px;
    }
  }

  &__close {
    position: relative;
    top: -2px;
    width: 30px;
    font-size: 18px;
  }
}

@media (min-width: $tabletBreakpoint) {
  .drafts {
    padding: 35px 30px 30px;
  }
}

@media (min-width: $desktopBreakpoint) {
  .drafts {
    padding: 0 30px 40px 40px;

    &__head {
      display: flex;
      align-items: center;
    }

    &__close {
      margin-left: -33px;
      margin-right: 2px;
      top: 2px;
    }
  }
}
</style>
